import { render, staticRenderFns } from "./triggerRules.vue?vue&type=template&id=dfb88ab4&scoped=true&"
import script from "./triggerRules.vue?vue&type=script&lang=ts&"
export * from "./triggerRules.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfb88ab4",
  null
  
)

export default component.exports